
// Fichier PreviewAdmin

import { useNavigate }                                      from "react-router-dom"
import { db, auth }                                         from "../firebase.config"  
import { useState, useEffect }                              from "react"
import StatisticsAdmin                                      from "../components/Administrateur/StatisticsAdmin" 
import { collection, getDocs, getDoc, Timestamp, addDoc, query, orderBy, doc, updateDoc, deleteDoc } from "firebase/firestore"
import { onAuthStateChanged, signOut}                       from "firebase/auth"
import deleteIcon                                           from "../assets/delete.png" 
import { getFunctions, httpsCallable }                      from "firebase/functions" 

import back from "../assets/back.png" 

function PreviewAdmin({ firstname, uid }) {
    const [signUpModal, setSignUpModal]                     = useState(false)
    const [showManage, setShowManage]                       = useState(false)
    const [show, setShow]                                   = useState(false)
    const [users, setUsers]                                 = useState([])
    const [usersVRP, setUsersVRP]                           = useState([])
    const [selectedUser, setSelectedUser]                   = useState("")
    const [selectedReason, setSelectedReason]               = useState("")
    const [customReason, setCustomReason]                   = useState("")
    const [startDate, setStartDate]                         = useState("")
    const [endDate, setEndDate]                             = useState("")
    const [message, setMessage]                             = useState("")
    const [admin, setAdmin]                                 = useState({})
    const [unavailabilities, setUnavailabilities]           = useState([])
    const [userNames, setUserNames]                         = useState({})
    const [showHistorique, setShowHistorique]               = useState(false)
    const [historique, setHistorique]                       = useState([])
    const [showDeleteUser, setshowDeleteUser]               = useState(false) 
    const [showDeleteConfirm, setShowDeleteConfirm]         = useState(false)
    const [manageDpt, setManageDpt]                         = useState(false)
    const [userDepartments, setUserDepartments]             = useState([])
    const [searchTerm, setSearchTerm]                       = useState('')
    const [departments, setDepartments]                     = useState([])
    const [showSuccessUserDeleted, setshowSuccessUserDeleted] = useState(false)
    const [msgDepartement, setMsgDepartement]               = useState("")
    const [showSuccessIndispo, setShowSuccessIndispo]       = useState(false)
    const navigate                                          = useNavigate()

    useEffect(() => {
        const fetchUnavailabilities = async () => {
            const unavailabilityQuery = query(
                collection(db, "unavailabilities"),
                orderBy("date", "desc") 
            )

            const unavailabilitySnapshot = await getDocs(unavailabilityQuery)
            const unavailabilityList = unavailabilitySnapshot.docs.map(doc => doc.data())
            setUnavailabilities(unavailabilityList)
        }

        const fetchUserNames = async () => {
            const usersSnapshot = await getDocs(collection(db, "users"))

            const usersList = usersSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }))

            const userNameMap = {}

            usersList.forEach(user => {
                userNameMap[user.id] = `${user.firstname} ${user.lastname}`
            })

            setUserNames(userNameMap)
        }

        const fetchHistorique = async () => {
                const historiqueQuery = query(
                    collection(db, "historiqueAdmin"),
                    orderBy("date", "desc") 
                )
    
                const historiqueSnapshot = await getDocs(historiqueQuery)
                const historiqueList = historiqueSnapshot.docs.map(doc => doc.data())
                setHistorique(historiqueList)
        }

        fetchUnavailabilities()
        fetchUserNames()
        fetchHistorique()
    }, [])

    // Récupère la liste des VRP
    useEffect(() => {
        const fetchVrp = async () => {
            const usersSnapshot = await getDocs(collection(db, "users"))

            const usersList = usersSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }))

            const VRP = usersList.filter((user) => user.role === "commercial")
            setUsersVRP(VRP)
        }

        fetchVrp()
    })

    useEffect(() => {
        const fetchUsers = async () => {
            const usersSnapshot = await getDocs(collection(db, "users"))

            const usersList = usersSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }))

            const updatedUsers = usersList.filter((user) => !user.deleted)
            setUsers(updatedUsers)
        }

        const fetchAdmin = async (user) => {
            const adminDoc = await getDoc(doc(db, "users", user.uid))
            setAdmin({ id: user.uid, ...adminDoc.data() })
        }

        onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchAdmin(user)
            }
        })

        fetchUsers()
    }, [])

    // Récupère les départements affiliés du user sélectionné
    useEffect(() => {
        if (selectedUser) {
            const fetchUserDepartments = async () => {
                const userDoc = await getDoc(doc(db, "users", selectedUser))

                if (userDoc.exists()) {
                    setUserDepartments(userDoc.data().departments || [])
                }
            }
            fetchUserDepartments()
        }
    }, [selectedUser])

    // Récupère les départements retrouvés par Google
    useEffect(() => {
        const service = new window.google.maps.places.AutocompleteService()
        service.getPlacePredictions(
            {
                input: searchTerm,
                componentRestrictions: { country: 'fr' },
                types: ['(regions)']
            },
            (predictions, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
                    const filteredDepartments = predictions
                        .filter(prediction => prediction.types.includes('administrative_area_level_2'))
                        .filter(prediction => prediction.description.endsWith(", France"))
                        .map(prediction => prediction.description.replace(", France", ""));
                    setDepartments(filteredDepartments);
                }
            }
        )
    }, [searchTerm])

    // Ajoute une indisponibilité à un VRP
    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!selectedUser || !selectedReason || !startDate || !endDate) {
            setMessage("Veuillez remplir tous les champs.")
            return
        }

        const reason = selectedReason === "Autre" ? customReason : selectedReason
        const currentDate = new Date()
        const endDateObj = new Date(endDate)

        try {
            // Vérifie si la date de fin est déjà dépassée
            const isEndDatePassed = endDateObj < currentDate

            await addDoc(collection(db, "unavailabilities"), {
                date: new Date(),
                userId: selectedUser,
                reason: reason,
                startDate: Timestamp.fromDate(new Date(startDate)),
                endDate: Timestamp.fromDate(new Date(endDate)),
                adminName: `${admin.firstname} ${admin.lastname}`
            })

            // Mets à jour le doc du user pour indiquer qu'il est indispo
            await updateDoc(doc(db, "users", selectedUser), {
                isUnavailable: !isEndDatePassed,
            })

            await addDoc(collection(db, "historiqueAdmin"), {
                userId: uid,
                date: new Date(),
                action: `VRP ${userNames[selectedUser]} rendu indisponible du ${new Date(startDate).toLocaleDateString('fr-FR')} au ${new Date(endDate).toLocaleDateString('fr-FR')}`,
            })

            setMessage("Indisponibilité ajoutée avec succès. L'utilisateur ne pourra pas se connecter durant la période saisie.")
            
            setSelectedUser("")
            setSelectedReason("")
            setCustomReason("")
            setStartDate("")
            setEndDate("")
            setShowManage(false)
            setShowSuccessIndispo(true)
        } 
        catch (error) {
            console.error("Erreur lors de l'ajout de l'indisponibilité: ", error)
            setMessage("Erreur lors de l'ajout de l'indisponibilité.")
        }
    }

    // Recherche des indisponibilités
    const handleSearch = async () => {
        try {
            const unavailabilitySnapshot = await getDocs(collection(db, "unavailabilities"))
            const unavailabilityList = unavailabilitySnapshot.docs.map(doc => doc.data())

            // Filtrer les résultats en fonction de startDate et endDate
            const filteredUnavailabilities = unavailabilityList.filter(unavailability => {

                const startDateTimestamp = unavailability.startDate?.seconds || 0
                const endDateTimestamp = unavailability.endDate?.seconds || Number.MAX_SAFE_INTEGER

                if (startDate && endDate) {
                    const startTimestamp = new Date(startDate).getTime() / 1000;
                    const endTimestamp = new Date(endDate).getTime() / 1000;
                    return startTimestamp <= endDateTimestamp && endTimestamp >= startDateTimestamp
                } 
                else if (startDate) {
                    const startTimestamp = new Date(startDate).getTime() / 1000
                    return startTimestamp <= endDateTimestamp
                } 
                else if (endDate) {
                    const endTimestamp = new Date(endDate).getTime() / 1000
                    return endTimestamp >= startDateTimestamp
                }

                return true
            })

            setUnavailabilities(filteredUnavailabilities)
        } 
        catch (error) {
            console.error("Erreur lors de la récupération et du filtrage des indisponibilités : ", error)
        }
    }

    // Supprime un user des VRP
    const handleDeleteUser = async () => {
        try {
            if (!selectedUser) {
                setMessage("Veuillez sélectionner un utilisateur.");
                return
            }

            // Supprime le user de Firebase Authentication
            const functions = getFunctions()
            const deleteUserFunction = httpsCallable(functions, 'deleteUser')
            await deleteUserFunction({ uid: selectedUser })

            // Supprime le user de la collection users
            const userDocRef = doc(db, "users", selectedUser)
            await deleteDoc(userDocRef)

            // User supprimé ajouté dans la collection usersDeleted
            await addDoc(collection(db, "usersDeleted"), {
                firstname: `${userNames[selectedUser]}`,
                date: new Date(),
            })

            // Historique admin mis à jour
            await addDoc(collection(db, "historiqueAdmin"), {
                action: `Utilisateur supprimé : ${userNames[selectedUser]}`,
                date: new Date(),
                userId: uid,
            })

            // Déconnecte le user supprimé des appareils
            onAuthStateChanged(auth, async (selectedUser) => {
                if (selectedUser) {
                    const userDoc = await getDoc(doc(db, "users", selectedUser.uid))

                    if (!userDoc.exists()) {
                        await signOut(auth)
                        window.location.reload()
                        //navigate("/connexion")
                    }
                }
            })

            setMessage(
                `L'utilisateur ${userNames[selectedUser]} a été supprimé avec succès.`
            )

            setSelectedUser("")
            setShowDeleteConfirm(false)
            setshowDeleteUser(false)
            setshowSuccessUserDeleted(true)
        } 
        catch (error) {
            console.error("Erreur lors de la suppression de l'utilisateur :", error)
            setMessage("Erreur lors de la suppression de l'utilisateur.")
        }
    }

    // Ajoute un département au user sélectionné
    const addDepartment = async (department) => {
        if (department) {
            await updateDoc(doc(db, "users", selectedUser), {
                departments: [...userDepartments, department]
            })

            // Historique admin mis à jour
            await addDoc(collection(db, "historiqueAdmin"), {
                action: `Nouveau département affilié au VRP ${userNames[selectedUser]} : ${department} `,
                date: new Date(),
                userId: uid,
            })

            setUserDepartments([...userDepartments, department])
            setMsgDepartement(`${department} ajouté avec succès.`)
        }
    }

    // Supprime un département au user sélectionné
    const deleteDepartment = async (department) => {
        const updatedDepartments = userDepartments.filter(dpt => dpt !== department)

        await updateDoc(doc(db, "users", selectedUser), {
            departments: updatedDepartments
        })

        // Historique admin mis à jour
        await addDoc(collection(db, "historiqueAdmin"), {
            action: `Département affilié au VRP ${userNames[selectedUser]} supprimé  : ${department} `,
            date: new Date(),
            userId: uid,
        })

        setUserDepartments(updatedDepartments)
        setMsgDepartement(`${department} supprimé avec succès.`)
    }

    return (
        <div className="preview-section preview-admin">
            <header>
                <h1>Tableau de bord</h1>  
            </header>

            <div className="hello">
                <h2>Bonjour, <span className="name">{firstname}</span> !</h2>
                <p>Voici votre tableau de gestion administrateur</p>
            </div>

            <div className="content">
                <StatisticsAdmin />

                <div className="droits-admin"> 
                    <h2>Sélectionner une action</h2>
                    <button className="button" onClick={() => setSignUpModal(true)} >Inscrire un nouvel utilisateur</button>
                    <button className="button" onClick={() => setshowDeleteUser(true)} >Supprimer un utilisateur</button>
                    <button className="button" onClick={() => setManageDpt(true)} >Gérer les départements des VRP</button>
                    <button className="button" onClick={() => setShowManage(true)}>Rendre indisponible un VRP</button>
                    <button className="button" onClick={() => setShow(true)} >Voir la fiche des indisponibilités</button>
                    <button className="button" onClick={() => setShowHistorique(true)}>Voir l'historique des actions admin</button>
                </div>
            </div>

            {signUpModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p>Vous allez être redirigé vers la page d'inscription.<br></br><br></br>Êtes-vous sûr de vouloir inscrire un nouvel utilisateur ?</p>
                        <div className="mini-buttons">
                            <button onClick={() => navigate("/inscription")}>Oui</button>
                            <button onClick={() => setSignUpModal(false)}>Non</button>
                        </div>
                    </div> 
                </div>
            )}

            {showDeleteUser && (
                <div className="modal">
                    <div className="modal-content">
                        <div className="user-list">
                            <h3 style={{marginBottom: "30px"}}>Supprimer un utilisateur</h3>
                            <select className="custom-select" value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                                <option value="">Sélectionner un utilisateur</option>
                                {users.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {userNames[user.id]}
                                    </option>
                                ))}
                            </select>
                            {selectedUser && (
                                <div>
                                    <button className="button-colored" onClick={() => setShowDeleteConfirm(true)}>Supprimer</button>
                                </div>
                            )}
                            <button className="cancel" onClick={() => {setShowDeleteConfirm(false); setshowDeleteUser(false)}}>Annuler</button>
                        </div>
                    </div> 
                </div>
            )}

            {showDeleteConfirm && (
                <div className="modal">
                    <div className="modal-content">
                        <p>Êtes-vous sûr de vouloir supprimer l'utilisateur {userNames[selectedUser]} ?</p>
                        {message && <p style={{margin: "0 20px", color: "grey"}}>{message}</p>}
                        <div className="mini-buttons">
                        <button onClick={handleDeleteUser}>Oui</button>
                        <button  onClick={() => {setShowDeleteConfirm(false); setshowDeleteUser(false)}}>Non</button>
                        </div>
                    </div>
                </div>
            )}

            {showSuccessUserDeleted && (
                <div className="modal">
                    <div className="modal-content"> 
                        <p style={{marginBottom: "20px"}}>Utilisateur supprimé avec succès !</p>
                        <button onClick={() => setshowSuccessUserDeleted(false)} className="button-colored">Fermer</button>
                    </div>
                </div>
            )}

            {showManage && (
                <section className="modal">
                    <form onSubmit={handleSubmit} className="modal-content modal-form">
                        <h2>Indisponibilités</h2>

                        <select value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                            <option value="">Sélectionnez un VRP</option>
                            {usersVRP.map(user => (
                                <option key={user.id} value={user.id}>{user.firstname} {user.lastname}</option>
                            ))}
                        </select><br></br>

                        <select value={selectedReason} onChange={(e) => setSelectedReason(e.target.value)}>
                            <option value="">Sélectionnez une raison</option>
                            <option value="Arrêt maladie">Arrêt maladie</option> 
                            <option value="Congé vacances">Congés vacances</option>
                            <option value="Autre">Autre</option>
                        </select>

                        {selectedReason === "Autre" && (  
                            <div>
                                <input 
                                    type="text" 
                                    value={customReason} 
                                    onChange={(e) => setCustomReason(e.target.value)} 
                                    placeholder="Entrez la raison" 
                                />
                            </div>
                        )}

                        <label className="label">Date de début</label>
                        <input className="custom-select" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        <label className="label">Date de fin</label>
                        <input className="custom-select" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />

                        <button className="button-colored" type="submit">Déconnecter le VRP</button><br></br>
                        <button className="cancel" onClick={() => setShowManage(false)}>Annuler</button>
                    </form>
                </section>
            )}

            {showSuccessIndispo && (
                <div className="modal">
                    <div className="modal-content">
                        <p style={{marginBottom: "20px"}}>Indisponibilité ajoutée avec succès. L'utilisateur ne pourra pas se connecter durant la période saisie.</p>
                        <button className="button-colored" onClick={() => setShowSuccessIndispo(false)}>Fermer</button>
                    </div>
                </div>
            )}

            {show && (
                <div className="tableau-indispo">
                    <button onClick={() => setShow(false)} className="button-back"><img src={back} alt="retour" /></button>
                    <div className="date-inputs">
                        <div>
                            <label className="label">Date de début :</label><br></br>
                            <input className="custom-select" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        </div>
                        <div>
                            <label className="label">Date de fin :</label><br></br>
                            <input className="custom-select" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                        </div>
                        <button className="button-colored" onClick={handleSearch}>Rechercher</button>
                    </div>
                    <h2>Historique des indisponibilités</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Administrateur</th>
                                <th>VRP</th>
                                <th>Date de début</th>
                                <th>Date de fin</th>
                                <th>Raison</th>
                            </tr>
                        </thead>
                        <tbody>
                            {unavailabilities.map((unavailability, index) => (
                                <tr key={index}>
                                    <td>{unavailability?.adminName || " "}</td>
                                    <td>{userNames[unavailability?.userId] || " "}</td>
                                    <td>{new Date(unavailability.startDate?.seconds * 1000).toLocaleDateString() || " "}</td>
                                    <td>{new Date(unavailability.endDate?.seconds * 1000).toLocaleDateString() || " "}</td>
                                    <td>{unavailability?.reason || " "}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {showHistorique && (
                <div className="modal-page">
                     <button onClick={() => setShowHistorique(false)} className="button-back"><img src={back} alt="retour" /></button>
                    <div>
                        <h2 style={{margin: "20px 0"}}>Historique des actions des administrateurs</h2>

                        <table className="tableau-action-admin" border="1">
                            <thead>
                                <tr style={{background: "#3D9B9B", color: "white"}}>    
                                    <th style={{width: "40%"}}>Action</th>
                                    <th style={{width: "30%"}}>Administrateur</th>
                                    <th style={{width: "30%"}}>Date</th>
                                </tr> 
                            </thead>
                            <tbody>
                                {historique.map((entry, index) => (
                                    <tr key={index}>
                                        <td>{entry.action || " "}</td>
                                        <td>{userNames[entry.userId] || " "}</td>
                                        <td>{new Date(entry.date?.seconds * 1000).toLocaleString() || " "}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            {manageDpt && (
                <div className="modal">
                    <div className="modal-content"> 

                        <select className="custom-select supp" value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                            <option value="">Sélectionner un utilisateur</option>
                            {usersVRP.map((user) => (
                                <option key={user.id} value={user.id}> 
                                    {userNames[user.id]}
                                </option>
                            ))}
                        </select>

                        {msgDepartement && (<p style={{margin: "20px", marginTop: "0", textAlign: "center", color: "green"}}>{msgDepartement}</p>)} 

                        {selectedUser && (
                            <>
                                <ul>
                                    <p style={{color: "grey", marginBottom: "10px", textAlign: "start", fontSize: "14px"}}>Départements affiliés au VRP :</p>
                                    {userDepartments.map(department => (
                                        <li key={department} style={{display: "flex", alignItems: "center"}}>
                                            {department}
                                            <button className="btn-supp" onClick={() => deleteDepartment(department)}><img style={{width: "17px", height: "17px"}} src={deleteIcon} alt="" /></button>
                                        </li>
                                    ))}
                                </ul><br></br>

                                <input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Rechercher un département" />

                                <ul>
                                    {departments.map(department => (
                                        <li style={{cursor: "pointer"}} className="supp-dpt" key={department} onClick={() => addDepartment(department)}>{department}</li>
                                    ))}
                                </ul>

                                <button style={{marginTop: "20px"}} className="button-colored" onClick={() => setManageDpt(false)}>Fermer</button>
                            </>
                        )}

                        <button className="cancel" onClick={() => setManageDpt(false)}>Annuler</button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PreviewAdmin  