import { collection, getDocs, query, where }        from "firebase/firestore"
import { useState, useEffect }                      from "react"
import { db }                                       from "../../firebase.config"
import FeuilleHebdo                                 from "./FeuilleHebo"
import back                                         from "../../assets/back.png"


function SearchFeuillesHebdo() {
    const [showFilters, setShowFilters]             = useState(false)
    const [username, setUsername]                   = useState("")
    const [month, setMonth]                         = useState("")
    const [year, setYear]                           = useState("")
    const [filteredReports, setFilteredReports]     = useState([])
    const [usersMap, setUsersMap]                   = useState({})
    const [suggestions, setSuggestions]             = useState([])
    const [fdrSemaine, setFdrSemaine]               = useState([])
    const [showFeuillesHebdo, setShowFeuillesHebdo] = useState(false)
    const [error, setError]                         = useState("")

    const toggleFilters = () => setShowFilters(!showFilters)

    // Récupère le nom des users 
    useEffect(() => {
        const fetchUsers = async () => {
            const usersSnapshot = await getDocs(collection(db, "users"))
            const usersData = {}

            usersSnapshot.forEach(doc => {
                const data = doc.data()
                usersData[doc.id] = `${data.firstname} ${data.lastname}`
            })

            setUsersMap(usersData)
        }

        fetchUsers()
    }, [])

    // Récupère les fiches de route 
    const searchReports = async () => {
        try {
            const feuillesRouteRef = collection(db, 'feuillesDeRoute')
            let q = query(feuillesRouteRef)
    
            if (username) {
                const userId = Object.keys(usersMap).find(key => usersMap[key] === username)

                if (userId) {
                    q = query(q, where('userId', '==', userId))
                }
            }
    
            const querySnapshot = await getDocs(q)

            const now = new Date()
            const dayOfWeek = now.getDay() // 0 = dimanche, 1 = lundi, ..., 6 = samedi
            const startOfWeek = new Date(now)
            startOfWeek.setDate(now.getDate() - dayOfWeek)
            startOfWeek.setHours(0, 0, 0, 0)
    
            const endOfWeek = new Date(now)
            endOfWeek.setDate(now.getDate() + (6 - dayOfWeek))
            endOfWeek.setHours(23, 59, 59, 999)
            
            const feuillesRouteData = querySnapshot.docs
                .map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                .filter((feuille) => {
                    const feuilleDate = feuille.date.toDate()
                    const feuilleMonth = feuilleDate.getMonth() + 1 // Les mois commencent à 0, donc ajouter 1
                    const feuilleYear = feuilleDate.getFullYear()
                    const monthMatches = month ? feuilleMonth === parseInt(month) : true
                    const yearMatches = year ? feuilleYear === parseInt(year) : true

                    const isNotInCurrentWeek = feuilleDate < startOfWeek || feuilleDate > endOfWeek
                    return monthMatches && yearMatches && isNotInCurrentWeek
                })
    
            setFilteredReports(feuillesRouteData)
            setShowFeuillesHebdo(true)

            if (!filteredReports.length > 0) {
                setError("Aucune feuille hebdomadaire trouvée avec les filtres sélectionnés.")
            }
            else {
                setError("") 
            }
        } 
        catch (error) {
            console.error("Erreur lors de la recherche des feuilles de route :", error)
        }
    }

    // Récupère les documents de fdrSemaine
    useEffect(() => {
        const fetchFdrSemaine = async () => {
            const fdrSemaineSnapshot = await getDocs(collection(db, "fdrSemaine"))

            const fdrData = fdrSemaineSnapshot.docs
            .map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            .filter(doc => doc.work !== false)
            
            setFdrSemaine(fdrData)
        }
        fetchFdrSemaine()
    }, [])

    // Affiche les suggestions de nom des VRP
    const handleUsernameChange = (e) => {
        const value = e.target.value 
        setUsername(value)

        if (value) {
            const filteredSuggestions = Object.values(usersMap).filter((name) => 
                name.toLowerCase().includes(value.toLowerCase())
            )
            setSuggestions(filteredSuggestions)
        }
        else {
            setSuggestions([])
        }
    }

    // Permet de sélectionner une suggestion
    const selectSuggestion = (name) => {
        setUsername(name)
        setSuggestions([])
    }

    return (
        <div>
            <button style={{marginTop: "20px"}} className="button-colored" onClick={toggleFilters}>Rechercher une feuille hebdomadaire</button>

            {showFilters && (
                <div className="search-feuille-hebdo" style={{ marginTop: '20px', boxShadow: "#cfcfcf 2px 2px 15px", padding: "20px", borderRadius: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}> 
                    <div style={{width: "21%", position: "relative"}}>
                        <label className="label">Nom du VRP</label><br></br>
                        <input style={{width: "100%"}} type="text" value={username} onChange={handleUsernameChange} placeholder="Rechercher le nom d'un VRP" />

                        {suggestions.length > 0 && (
                            <ul style={{ listStyle: 'none', padding: 0, marginTop: '5px', background: 'white', border: '1px solid #cfcfcf', position: "absolute", width: "100%", top: "70px" }}> 
                                {suggestions.map((suggestion, index) => (
                                    <li key={index} onClick={() => selectSuggestion(suggestion)} style={{ padding: '5px', cursor: 'pointer', borderBottom: '1px solid #cfcfcf' }}>{suggestion}</li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div  style={{width: "21%"}}>
                        <label className="label">Mois</label><br></br>
                        <select style={{width: "100%"}} value={month} onChange={(e) => setMonth(e.target.value)}>
                            <option value="">Tous</option>
                            {Array.from({ length: 12 }, (_, i) => (
                                <option key={i + 1} value={i + 1}>
                                    {new Date(0, i).toLocaleString('fr-FR', { month: 'long' })}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div style={{width: "21%"}}>
                        <label className="label">Année</label><br></br>
                        <input style={{width: "100%"}} type="number" value={year} onChange={(e) => setYear(e.target.value)} placeholder="Année" min="2022" max={new Date().getFullYear()} />
                    </div>

                    <button className="button-colored" style={{width: "21%"}} onClick={searchReports}>Rechercher</button>
                </div>
            )}

            {error && <p style={{color: "red", marginTop: "20px"}}>{error}</p>}
            
            {showFeuillesHebdo && filteredReports.length > 0 && ( 
                <div style={{position: "fixed", top: "0", left: "0", right: "0", bottom: "0", width: "100%", height: "100%", padding: "10px", background: "white", zIndex: "999", overflow: "scroll"}}> 
                    <div style={{background: "white"}}>  
                    <button className="button-back" onClick={() => setShowFeuillesHebdo(false)} style={{ marginBottom: '20px' }}><img src={back} alt="retour" /></button> {/* Bouton pour fermer */}
                        <ul>
                            <FeuilleHebdo usersMap={usersMap} filteredReports={filteredReports} fdrSemaine={fdrSemaine} />
                        </ul>
                    </div>
                </div>
            )}
                
        </div>
    ) 
}

export default SearchFeuillesHebdo 