import React, { useRef }                                from 'react'
import { useState }                                     from 'react'
import jsPDF                                            from "jspdf"  
import html2canvas                                      from "html2canvas"

function FeuilleHebdo({  usersMap, filteredReports, fdrSemaine }) {
    const days                                          = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi']
    const [openWeek, setOpenWeek]                       = useState(null)
    const pageRef                                       = useRef()  

    const formatDate = (date) =>
        date?.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
    })

    const formatTime = (date) =>
        date?.toLocaleTimeString('fr-FR', {
            hour: '2-digit',
            minute: '2-digit',
    })

    const getDayName = (timestamp) => {
        const date = new Date(timestamp.seconds * 1000);
        return date
            .toLocaleDateString('fr-FR', { weekday: 'long' })
            .charAt(0)
            .toUpperCase() + date.toLocaleDateString('fr-FR', { weekday: 'long' }).slice(1)
    }

    const getStartOfWeek = (date) => {
        const day = date.getDay()
        const diffToMonday = day === 0 ? 6 : day - 1
        const startOfWeek = new Date(date)
        startOfWeek.setDate(date.getDate() - diffToMonday)
        startOfWeek.setHours(0, 0, 0, 0)
        return startOfWeek
    }

    const getEndOfWeek = (startOfWeek) => {
        const endOfWeek = new Date(startOfWeek)
        endOfWeek.setDate(startOfWeek.getDate() + 4) // Jusqu'à vendredi soir
        endOfWeek.setHours(23, 59, 59, 999)
        return endOfWeek
    }

    const getSignatureData = (startOfWeek, endOfWeek, userId) => {
        const startTimestamp = startOfWeek.getTime() / 1000
        const endTimestamp = endOfWeek.getTime() / 1000
    
        const match = fdrSemaine.find((doc) => {
            const dateSemaineTimestamp = doc.dateSemaine.seconds

            return (
                dateSemaineTimestamp >= startTimestamp &&
                dateSemaineTimestamp <= endTimestamp &&
                doc.userId === userId
            )
        })
    
        return match ? { signature: match.signature, date: new Date(match?.date?.seconds * 1000) } : null;
    }
    
    const groupStopsByWeek = () => {
        const weeks = {}

        filteredReports.forEach((feuille) => {
            const date = new Date(feuille.date.seconds * 1000)
            const startOfWeek = getStartOfWeek(date)
            const endOfWeek = getEndOfWeek(startOfWeek)
            const weekKey = `${startOfWeek.toISOString().split('T')[0]}_${endOfWeek.toISOString().split('T')[0]}_${feuille.userId}`

            if (!weeks[weekKey]) {
                const signatureData = getSignatureData(startOfWeek, endOfWeek, feuille.userId)
                weeks[weekKey] = { userId: feuille.userId, startOfWeek, endOfWeek, stopsByDay: {}, totalKmByDay: {}, motifsByDay: {}, totalVisits: 0, clientVisits: 0, prospectVisits: 0, signature: signatureData?.signature, signatureDate: signatureData?.date };

                days.forEach(day => {
                    weeks[weekKey].stopsByDay[day] = []
                    weeks[weekKey].totalKmByDay[day] = 0
                    weeks[weekKey].motifsByDay[day] = []
                })
            }

            const dayName = getDayName(feuille.date)

            if (weeks[weekKey].stopsByDay.hasOwnProperty(dayName)) {
                if (feuille.isVisitsStarted === false && feuille.motif) {
                    weeks[weekKey].motifsByDay[dayName].push(feuille.motif)
                }

                feuille?.stops?.forEach(stop => {
                    weeks[weekKey].stopsByDay[dayName].push({ ...stop, dayName })
                    weeks[weekKey].totalKmByDay[dayName] += stop.distance || 0

                    if (stop.status) {
                        weeks[weekKey].totalVisits++

                        if (stop.status === "Client") {
                            weeks[weekKey].clientVisits++
                        } 
                        else if (stop.status === "Prospect") {
                            weeks[weekKey].prospectVisits++
                        }
                    }
                })
            }
        })
        return weeks
    }

    const weeksGrouped = groupStopsByWeek()

    const maxRows = Math.max(
        ...Object.values(weeksGrouped).map(weekData =>
            Math.max(...Object.values(weekData.stopsByDay).map(stops => stops.length))
        ), 0 
    )

    const formatDistance = (distance) => {
        if (distance < 1000) {
            return `${distance.toFixed(0)} m`
        }
        return `${(distance / 1000).toFixed(2)} km`
    }

    const generatePDF = (input, filename, signatureUrl) => {
        if (!input) {
            console.error('Erreur : référence à l\'élément non valide')
            return
        }
    
        html2canvas(input, {
            useCORS: true,
            scale: 2, 
        }).then(canvas => {

            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF('p', 'mm', 'a4')
            const pdfWidth = pdf.internal.pageSize.getWidth()
            const pdfHeight = pdf.internal.pageSize.getHeight()
            const canvasWidth = canvas.width
            const canvasHeight = canvas.height
            const ratio = canvasWidth / canvasHeight
            let width = pdfWidth
            let marginLeft = 0

            if (window.innerWidth < 968) {
                marginLeft = 200 * (pdfWidth / canvasWidth) 
                width = pdfWidth - 2 * marginLeft 
            }
            const height = width / ratio
    
            let position = 0
    
            const addSignatureAndPageNumberToPDF = (pdf, pageHeight, pageNumber, totalPages) => {

                if (signatureUrl) {
                    const signatureImg = new Image()
                    signatureImg.src = signatureUrl

                    const signatureWidth = 15
                    const signatureHeight = 10
                    const xPos = pdfWidth - signatureWidth - 10
                    const yPos = pageHeight - signatureHeight - 10

                    pdf.addImage(signatureImg, 'PNG', xPos, yPos, signatureWidth, signatureHeight)
                }

                pdf.setFontSize(10)
                pdf.text(`Page ${pageNumber} / ${totalPages}`, pdfWidth -10, pageHeight - 5, { align: 'right' })
            }
    
            if (height > pdfHeight) {
                const totalPages = Math.ceil(canvasHeight / (canvasWidth * pdfHeight / pdfWidth))

                for (let i = 0; i < totalPages; i++) {

                    const pageCanvas = document.createElement('canvas')
                    pageCanvas.width = canvasWidth
                    pageCanvas.height = canvasWidth * pdfHeight / pdfWidth

                    const pageContext = pageCanvas.getContext('2d')
                    pageContext.drawImage(canvas, 0, position, canvasWidth, pageCanvas.height, 0, 0, pageCanvas.width, pageCanvas.height)

                    const pageImgData = pageCanvas.toDataURL('image/png')

                    if (i > 0) {
                        pdf.addPage()
                    }

                    pdf.addImage(pageImgData, 'PNG', marginLeft, 0, width, pdfHeight)
                    addSignatureAndPageNumberToPDF(pdf, pdfHeight, i + 1, totalPages)
                    position += pageCanvas.height
                }
            } 
            else {
                pdf.addImage(imgData, 'PNG', marginLeft, 0, width, height)
                addSignatureAndPageNumberToPDF(pdf, height, 1, 1)
            }
    
            pdf.save(filename)

        }).catch(error => {
            console.error('Erreur lors de la génération du PDF :', error)
        })
    }
    
    const downloadPDF = () => {
        const input = pageRef.current
        const signatureData = weeksGrouped[openWeek]?.signature
        const signatureUrl = signatureData ? signatureData.url : null
    
        generatePDF(input, "feuille-hebdomadaire.pdf", signatureUrl)
    }
    
    return (
        <div>
            {Object.entries(weeksGrouped).map(([weekKey, weekData]) => (
                <div key={weekKey} className='feuillehebdo'>
                    <div>
                        <button style={{margin: "20px", marginTop: "0"}} className='button-colored' onClick={() => setOpenWeek(openWeek === weekKey ? null : weekKey)} >
                            Semaine du {formatDate(weekData.startOfWeek)} au {formatDate(weekData.endOfWeek)}
                        </button>
                        <button className='button-colored' onClick={downloadPDF}>← Télécharger</button>
                    </div>
                   

                    {openWeek === weekKey && (
                        <div style={{ padding: "20px", display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                            <div className='print-page' ref={pageRef} style={{padding: "50px", display: "flex", flexDirection: "column", alignItems: "center",  width: "80%"}}>
                                <h1 style={{ fontSize: "20px", marginBottom: "30px", marginTop: "30px", textAlign: "center" }}>Validation de la feuille de route hebdomadaire</h1>
                                <p className='soussigne' style={{ lineHeight: "25px", marginBottom: "30px", textAlign: "center", width: "70%" }}>
                                    Je soussigné(e) <strong>{usersMap[weekData.userId]}</strong>, certifie l’authenticité des informations figurant sur la feuille de route ci-dessous qui rend compte des déplacements professionnels que j’ai effectué concernant la semaine du <strong>{`${formatDate(weekData.startOfWeek)}`}</strong> au <strong>{`${formatDate(weekData.endOfWeek)}`}</strong>.
                                </p>

                                <table border="1" style={{ width: '100%', borderCollapse: 'collapse', marginBottom: "30px" }}>
                                    <thead style={{ fontSize: "10px" }}>
                                        <tr>
                                            <th style={{ width: '10%', background: "#3D9B9B", color: "white" }}>Total <strong>{formatDistance(Object.values(weekData.totalKmByDay).reduce((a, b) => a + b, 0))}</strong></th>
                                            {days.map(day => (
                                                <th key={day} style={{ width: '18%', background: "#c7c7c7" }}>{day}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody style={{ fontSize: "10px" }}>
                                        {Array.from({ length: maxRows }).map((_, rowIndex) => (
                                            <tr key={rowIndex}>
                                                <td style={{ verticalAlign: 'top', background: "#c7c7c7" }}>
                                                    <div style={{ display: "flex", flexDirection: "column", padding: "5px" }}>
                                                        <strong>Nom</strong>
                                                        <strong>Km</strong>
                                                    </div>
                                                </td>

                                                {days.map(day => (
                                                    <td key={day} style={{ verticalAlign: 'top', fontSize: "10px" }}>
                                                        {weekData.stopsByDay[day] && weekData.stopsByDay[day][rowIndex] ? (
                                                            <div style={{ display: "flex", fontSize: "10px", flexDirection: "column", padding: "5px", height: "100%" }}>
                                                                <p><strong>{weekData.stopsByDay[day][rowIndex].name}</strong></p>
                                                                <p>{formatDistance(weekData.stopsByDay[day][rowIndex].distance)}</p>
                                                            </div>
                                                        ) : (
                                                            rowIndex === 0 && (
                                                                <div style={{ fontSize: "10px", background: "#e0e0e0", padding: "5px", textAlign: "center" }}>
                                                                    <p>Pas de déplacements</p>
                                                                    <p>Motif : <strong>{weekData.motifsByDay[day].length > 0 ? weekData.motifsByDay[day][0] : "Aucun"}</strong></p>
                                                                </div>
                                                            )
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}

                                        <tr>
                                            <td style={{ verticalAlign: 'top', padding: "10px", background: "#3D9B9B", color: "white" }}>
                                                <strong>Total km</strong>
                                            </td>
                                            {days.map(day => (
                                                <td key={day} style={{ verticalAlign: 'top', padding: "10px", background: "#3D9B9B", color: "white" }}>
                                                    <strong>{formatDistance(weekData.totalKmByDay[day] || 0)}</strong>
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table> 

                                <div className='hebdo-stats-print'>
                                    <p>Nombre total de visites <span>{weekData.totalVisits}</span></p>
                                    <p>Nombre de visites clients <span>{weekData.clientVisits}</span></p>
                                    <p>Nombre de visites prospects <span>{weekData.prospectVisits}</span></p>
                                </div>
                                
                                {weekData.signature ? (
                                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                        <p style={{marginBottom: "20px"}}>Signé le <strong>{formatDate(weekData.signatureDate)}</strong> à <strong>{formatTime(weekData.signatureDate)}</strong></p>
                                        <img src={weekData.signature} alt="Signature" style={{ maxWidth: '150px' }} />
                                    </div>
                                ) : (
                                    <p style={{color: "red", textAlign: "center", marginTop: "20px"}}>Cette fiche hebdomadaire n'a pas été signée.</p>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}

export default FeuilleHebdo 
